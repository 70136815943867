import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Link, Route, Switch } from 'react-router-dom'
import Profile from './Profile'
import Upgrades from './Upgrades'
import { Users } from 'mcore'
import { Button } from 'react-native-elements'
import { AppBar, Toolbar } from '@material-ui/core'
import firebase from 'firebase/compat/app'
import Progress from './utils/Progress'

const items = [
	{
		id: 'profile',
		title: 'Profile',
		component: Profile
	},
	{
		id: 'membership',
		title: 'Membership',
		component: Upgrades
	},
]

export default function Account() {

	const [userInfo] = useState(Users.getUserInfo())
	const [showLoader, setShowLoader] = useState(true)

	useEffect(() => {
		if (userInfo) {
			onPressLogout()
		}else{
			setShowLoader(false)
		}
	}, [userInfo])

	if(showLoader){
		return (
			<Progress/>
		)
	}
	
	return (
		<Grid container style={{
			display: 'flex',
			flexDirection: 'column'
		}}>
			<AppBar position='sticky'>
				<Toolbar></Toolbar>
			</AppBar>
			<div style={{
				display: 'flex',
				justifyContent: 'space-between',
				padding: '1em',
				background: 'red'
			}}>
				<div>
					<b><div>{userInfo.displayName}</div></b>
					<div>{userInfo.email}</div>
				</div>
				<div>
					<Button 
						buttonStyle={{
							paddingHorizontal: '2em'
						}}
						onPress={onPressLogout}
						title='Logout'/>
				</div>
			</div>
			<div style={{
				padding: '1em',
			}}>
				<div>
					{items.map((item) => (
						<div style={{
							marginTop: '1em',
							marginBottom: '1em'
						}} key={item.id}><Link to={`${item.id}`}>{item.title}</Link></div>
					))}
				</div>
				<div>
					<Switch>
						{items.map((item) => (
							<Route path={`/account/${item.id}`} component={item.component} />
						))}
					</Switch>
				</div>
			</div>
		</Grid>
	)

	async function onPressLogout(){
		console.log('Logging out...')
		await firebase.auth().signOut()
		window.open('/', '_self')
	}
}
