import React from 'react'
import {AppRegistry} from 'react-native';
import './index.css'
import App from './App';

// import IoniconsFont from 'react-native-vector-icons/Fonts/Ionicons.ttf';
// import MaterialCommunityIconsFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
// import SimpleLineIconsFont from 'react-native-vector-icons/Fonts/SimpleLineIcons.ttf';
// import iconMaterialFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
// import EntypoFont from 'react-native-vector-icons/Fonts/Entypo.ttf';
// import iconAntFont from 'react-native-vector-icons/Fonts/AntDesign.ttf';
// import OcticonsFont from 'react-native-vector-icons/Fonts/Octicons.ttf';
// import iconFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
// import ZocialFont from 'react-native-vector-icons/Fonts/Zocial.ttf';

// const MaterialCommunityIconsFontStyles = `@font-face {
//   src: url(${MaterialCommunityIconsFont});
//   font-family: MaterialCommunityIcons;
// }`;

// const materialCommunityIconsIcons = document.createElement('style');
// materialCommunityIconsIcons.type = 'text/css';
// if (materialCommunityIconsIcons.styleSheet) {
//   materialCommunityIconsIcons.styleSheet.cssText = MaterialCommunityIconsFontStyles;
// } else {
//   materialCommunityIconsIcons.appendChild(document.createTextNode(MaterialCommunityIconsFontStyles));
// }
// document.head.appendChild(materialCommunityIconsIcons);
// //
// const ZocialFontStyles = `@font-face {
//   src: url(${ZocialFont});
//   font-family: Zocial;
// }`;

// const zocialIcons = document.createElement('style');
// zocialIcons.type = 'text/css';
// if (zocialIcons.styleSheet) {
//   zocialIcons.styleSheet.cssText = ZocialFontStyles;
// } else {
//   zocialIcons.appendChild(document.createTextNode(ZocialFontStyles));
// }
// document.head.appendChild(zocialIcons);
// //
// const EntypoFontStyles = `@font-face {
//   src: url(${EntypoFont});
//   font-family: Entypo;
// }`;

// const entypoIcons = document.createElement('style');
// entypoIcons.type = 'text/css';
// if (entypoIcons.styleSheet) {
//   entypoIcons.styleSheet.cssText = EntypoFontStyles;
// } else {
//   entypoIcons.appendChild(document.createTextNode(EntypoFontStyles));
// }
// document.head.appendChild(entypoIcons);
// //
// const OcticonsFontStyles = `@font-face {
//   src: url(${OcticonsFont});
//   font-family: Octicons;
// }`;

// const octiconsIcons = document.createElement('style');
// octiconsIcons.type = 'text/css';
// if (octiconsIcons.styleSheet) {
//   octiconsIcons.styleSheet.cssText = OcticonsFontStyles;
// } else {
//   octiconsIcons.appendChild(document.createTextNode(OcticonsFontStyles));
// }
// document.head.appendChild(octiconsIcons);
// //
// const SimpleLineIconsFontStyles = `@font-face {
//   src: url(${SimpleLineIconsFont});
//   font-family: SimpleLineIcons;
// }`;

// const styleSimpleLineIcons = document.createElement('style');
// styleSimpleLineIcons.type = 'text/css';
// if (styleSimpleLineIcons.styleSheet) {
//   styleSimpleLineIcons.styleSheet.cssText = SimpleLineIconsFontStyles;
// } else {
//   styleSimpleLineIcons.appendChild(document.createTextNode(SimpleLineIconsFontStyles));
// }
// document.head.appendChild(styleSimpleLineIcons);

// //
// const iconAntStyles = `@font-face {
//   src: url(${iconAntFont});
//   font-family: AntDesign;
// }`;

// const styleAnt = document.createElement('style');
// styleAnt.type = 'text/css';
// if (styleAnt.styleSheet) {
//   styleAnt.styleSheet.cssText = iconAntStyles;
// } else {
//   styleAnt.appendChild(document.createTextNode(iconAntStyles));
// }
// document.head.appendChild(styleAnt);

// const iconFontStyles = `@font-face {
//   src: url(${iconFont});
//   font-family: FontAwesome;
// }`;

// const style = document.createElement('style');
// style.type = 'text/css';
// if (style.styleSheet) {
//   style.styleSheet.cssText = iconFontStyles;
// } else {
//   style.appendChild(document.createTextNode(iconFontStyles));
// }
// document.head.appendChild(style);

// const iconMaterialFontStyles = `@font-face {
//   src: url(${iconMaterialFont});
//   font-family: MaterialIcons;
// }`;
// const styleMaterial = document.createElement('style');
// styleMaterial.type = 'text/css';
// if (styleMaterial.styleSheet) {
//   styleMaterial.styleSheet.cssText = iconMaterialFontStyles;
// } else {
//   styleMaterial.appendChild(document.createTextNode(iconMaterialFontStyles));
// }
// document.head.appendChild(styleMaterial);

// const ioniconsFontStyles = `@font-face {
//   src: url(${IoniconsFont});
//   font-family: Ionicons;
// }`;

// const styleIonIcons = document.createElement('style');
// styleIonIcons.type = 'text/css';
// if (styleMaterial.styleSheet) {
//   styleIonIcons.styleSheet.cssText = ioniconsFontStyles;
// } else {
//   styleIonIcons.appendChild(document.createTextNode(ioniconsFontStyles));
// }

// document.head.appendChild(styleIonIcons);

if (module.hot) {
  module.hot.accept();
}

AppRegistry.registerComponent('Mapulator', () => App);
AppRegistry.runApplication('Mapulator', {
  initialProps: {},
  rootTag: document.getElementById('root'),
});

