import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Progress(props:{
   backdrop?: boolean
}) {

   const { backdrop } = props

   return (
      <View style={{
         flex: 1,
         justifyContent: 'center',
         alignItems: 'center',
         zIndex: 10000
      }}>
         {(backdrop ?? false) == false ? (
            <ActivityIndicator/>
         ) : (
            <Backdrop style={{
               background: '#11111180',
               zIndex: 1
            }} open={true}>
               <CircularProgress/>
            </Backdrop>
         )}
      </View>
   )
}
