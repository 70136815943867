import { View, Text, TouchableOpacity } from 'react-native'
import React, { useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Progress from './utils/Progress.web'
import { Button } from 'react-native-elements';
import Upgrades, { durations, plans } from 'mcore/lib/Upgrades';

export default function UpgradesList() {

	const [showLoader, setShowLoader] = useState(true)
	const [selectedTabIndex, setSelectedTabIndex] = useState<string>('year')
	const [availablePlans, setAvailablePlans] = useState<any[]>([])
	const cheapestPlanAmount = useMemo(() => {
		if(availablePlans.length == 0){
			return null
		}
		const __plan = availablePlans.find((p) => p.notes.productId == 'com.logisian.mapulator.lite.yearly') 
		return `${__plan.item.currency} ${(__plan.item.amount/100/12).toFixed(2)}`
	}, [availablePlans])

	useEffect(() => {
		fetchPlans()
	}, [])

	if(showLoader){ return <Progress backdrop/> }

	return (
		<Grid container xs={12} sm={12} md={10} style={{
			display: 'flex',
			justifyContent: 'center',
			padding: '1.5em',
			flexDirection: 'column',
			alignItems: 'center',
			alignSelf: 'center'
		}}>
			{cheapestPlanAmount != null && (
				<Text style={{
					fontSize: 22,
					marginVertical: '1em',
					textAlign: 'center',
					lineHeight: 35
				}}>Mapulator premium plans starts for as low as <b>{cheapestPlanAmount}</b>/month.</Text>
			)}
			<Grid item xs={10} sm={8} md={8} style={{
				width: '100%',
				display: 'flex',
				flex: 1,
				borderRadius: '3em',
			}}>
				<View style={{ 
					height: '3em',
					borderWidth: 1,
					borderRadius: 25,
					borderColor: '#dddddd',
					flex: 1,
					justifyContent: 'space-around',
					flexDirection: 'row',
					alignItems: 'center',
					padding: '2px',
				}}>
					{durations.map(({ id, title }) => (
						<TouchableOpacity
							style={{
								height: '100%',
								flex: 0.5,
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 25,
								backgroundColor: selectedTabIndex == id ? '#208be8' : undefined,
							}}
							onPress={() => onPressTab(id)}>
							<Text style={{
								fontWeight: 'bold',
								color: selectedTabIndex == id ? 'white' : undefined,
							}}>{title}</Text>
						</TouchableOpacity>
					))}
				</View>
			</Grid>
			<br />
			{durations.filter(({ id }) => selectedTabIndex == id).map(({ id, skus }) => {
				const subsciptionPlans = availablePlans.filter(
					(p) => skus.includes(p.notes.productId)
				)
				return (
					<TabPanel
						id={id}
						subsciptionPlans={subsciptionPlans}
						show={selectedTabIndex == id} />
				)
			})}
		</Grid>
	)

	function onPressTab(id: string){
		setSelectedTabIndex(id)
	}

	async function fetchPlans() {
		setShowLoader(true)
		try {
			
			let allSkus = [] as string[]
			plans.forEach(({ skus }) => {
				allSkus = [...allSkus, ...skus]
			})
			const promises = allSkus.map((id) => Upgrades.getPlan(id))
			const response = await Promise.allSettled(promises)
			const fulfilled = response.filter((result) => result.status == 'fulfilled') as { value: any }[]
			const values = fulfilled.map((result) => result.value)
			console.log('values', values)
			setAvailablePlans(values)
		} catch (error) {
			console.log('Error Getting Subscription Plans', error)
		}
		setShowLoader(false)
	}
}

function TabPanel(props:{
	show: boolean,
	id: string,
	subsciptionPlans: any[]
}){
	const { show, subsciptionPlans, id } = props

	if(show == false){ return <View/>}

	return (
		<Grid xs={12} style={{
			flex: 1,
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'row',
			flexWrap: 'wrap',
			width: '100%',
		}}>
			{subsciptionPlans.map(({ item, period, notes }) => {

				const productId = notes.productId as string
				const planId = productId.split('.')[productId.split('.').length - 2]
				const planDetails = plans.find(p => p.id == planId)

				return (
					<Grid xs={12} sm={6} style={{
						marginBottom: '1em'
					}}>
						<View style={{
							flex: 1,
							margin: '0.5em',
							borderWidth: 1,
							borderColor: '#dddddd',
							padding: '1.5em',
							borderRadius: 7,
							display: 'flex',
							justifyContent: 'space-between',
							height: '100%'
						}}>
							<View>
								<Text style={{
									textAlign: 'left',
									fontSize: 20,
									fontWeight: '500',
									marginTop: 10
								}}>{item.name}</Text>
								<Text style={{
									color: 'gray',
									marginBottom: 10,
									fontWeight: '300'
								}}>{item.description}</Text>
								<br />
								<div>
									{period == 'yearly' && (
										<b>
											<span style={{
												fontSize: 22
											}}>{item.currency} {(item.amount / 100 / 12).toFixed(2)}</span>
										</b>
									)}
									{period == 'monthly' && (
										<b>
											<span style={{
												fontSize: 22
											}}>{item.currency} {item.amount / 100}</span>
										</b>
									)}
									<span style={{
										fontSize: 14
									}}>/month</span>
								</div>
								{period == 'yearly' && <div style={{
									marginTop: '5px',
									fontSize: 12
								}}>
									<span>Billed {period} {item.currency} {item.amount / 100}</span>
								</div>}
								<br />
								{planDetails?.features?.map((title) => (
									<Text style={{
										marginVertical: 6
									}}>• {title}</Text>
								))}
								<br />
							</View>
							<a style={{
								textDecoration: 'none'
							}} target='_top' href={'https://web.mapulator.app/upgrade/' + productId}>
								<Button
									type='outline'
									title='Upgrade' />
							</a>
						</View>
					</Grid>
				)
			})}
		</Grid>
	)
}