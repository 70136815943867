// import { SessionGroup } from 'mcore/lib/Session'
import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom'
// import {
//    Projects
// } from 'mcore'
import {
   GoogleMap,
   useJsApiLoader,
   Circle,
   Marker,
   OverlayView,
   GroundOverlay,
} from '@react-google-maps/api';
import Grid from '@material-ui/core/Grid'
import Downloads from './Downloads';
import { useParams } from 'react-router-dom';

export const API_KEY = 'AIzaSyCOs-3a1SjL1xiQGBku5fmjj44dIENJv70'

interface Params {
   projectId: string
}

export default function SessionView() {

   const params = useParams() as Params
   const { projectId } = params
   // const [project, setProject] = useState<SessionGroup>()
   const [showLoader, setShowLoader] = useState(false)
   const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: API_KEY
   })

   console.log('params', params)

   // const [centerLocation, setCenterLocation] = useState()

   // useEffect(() => {
   //    fetchProject(params.projectId)
   // }, [params.projectId])

   // if (showLoader == true || project == null) {
   //    return (
   //       <p>Opening Project...</p>
   //    )
   // }

   // const { sessions } = project

   if(isLoaded == false) return <div>Loading...</div>

   return (
      <Grid style={{
         width: window.innerWidth,
         height: window.innerHeight,
      }} container>
         {params.projectId != null && <div style={{
            display: "flex",
            position: 'absolute',
            zIndex: 100,
            alignSelf: 'center',
            right: 0,
            left: 0,
            background: 'white',
            justifyContent: 'center',
            padding: '2em',
            flexDirection: 'column',
            alignItems: 'center'
         }}>
            <h1>This project cannot be opened on the web</h1>
            <Downloads/>
            <p>If you have the app installed, please tap on the button below on your mobile browser</p>
            <a href={`mapulator.app://projects/${projectId}`}><button>Open Project</button></a>
         </div>}
         <GoogleMap
            center={{
               lat: 12.9141,
               lng: 74.8560
            }}
            mapContainerStyle={{
               width: '100%',
               height: '100%'
            }}
            zoom={2}
            options={{
               zoomControl: false,
               scrollwheel: true,
               mapTypeControl: true,
               streetViewControl: false,
               fullscreenControl: false
            }}>
         </GoogleMap>
      </Grid>

   )

   // async function fetchProject(id: string) {
   //    setShowLoader(true)
   //    try {
   //       const response = await Projects.getProjectWithId(id)
   //       console.log('Response', response)
   //       setProject(response)
   //    } catch (error) {
   //       console.log('Error Fetching Project', error)
   //    }
   //    setShowLoader(false)
   // }
}
