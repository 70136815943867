import React, { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";
import "firebase/compat/analytics";
import Home from './Home';
import firestar from 'firestar'
import {
  BrowserRouter, Route, Switch
} from 'react-router-dom'
import SessionView from './SessionView';
import Upgrade from './Upgrade';
import Login from './Login';
import SignUp from './SignUp';
import Upgrades from './Upgrades';
import Progress from './utils/Progress.web';
import Account from './Account.web';
import { useDebounce } from './utils/index.web';

const firebaseConfig = {
  apiKey: 'AIzaSyCDbaWrAry0mlFstCBoePnDKBlqcRgDcRI',
  authDomain: 'logisian-mapulator-92210.firebaseapp.com',
  databaseURL: 'https://logisian-mapulator-92210.firebaseio.com',
  projectId: 'logisian-mapulator-92210',
  storageBucket: 'logisian-mapulator-92210.appspot.com',
  messagingSenderId: '923634062989',
  appId: '1:923634062989:web:45665564a9da15ae',
  measurementId: 'G-GX7F26F510',
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

firestar.init(
  'Mapulator - Web',
  'browser',
  () => firebase.firestore,
  () => firebase.auth,
  () => firebase.storage,
  () => firebase.database
)

export default function App() {

  const [showLoader, setShowLoader] = useState(true)
  const [userId, setUserId] = useState<string>()
  const debouncedUserAuthId = useDebounce(userId, 1000);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onAuthStateChanged);
    return () => {
      unsubscribe()
    }
  }, []);

  useEffect(
    () => {
      if (debouncedUserAuthId) {
        console.log('Debounced User', debouncedUserAuthId)
        setUserId(debouncedUserAuthId)
      }
    },
    [debouncedUserAuthId]
  );

  if (showLoader == true) {
    return <Progress />
  }

  return (
    <BrowserRouter >
      <Switch>
        <Route exact path={'/'} component={Home} />
        <Route path={'/projects/:projectId'} component={SessionView} />
        <Route exact path={'/upgrade'} component={Upgrades} />
        <Route path={'/upgrade/:productId'} component={Upgrade} />
        <Route path={'/login'} component={Login} />
        <Route path={'/signup'} component={SignUp} />
        <Route path={'/account'} component={Account} />
        <Route path={'/upgrades'} component={Upgrades} />
      </Switch>
    </BrowserRouter>
  )

  function onAuthStateChanged(user: firebase.User | null) {
    setShowLoader(false)
  }
}
