"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateResultOptions = exports.subscriptionSkus = exports.PurchaseOptions = exports.productSkus = exports.SubscriptionStatusOptions = exports.PlatformOptions = exports.MembershipOptions = exports.MembershipTypes = void 0;
const firestar_1 = __importStar(require("firestar"));
const Upgrades_1 = require("./Upgrades");
exports.MembershipTypes = {
    'trial': 'Trial',
    'free': 'Free',
    'premium': 'Premium'
};
exports.MembershipOptions = {
    'trial': 'Trial',
    'free': 'Free',
    'lite': 'Lite',
    'pro': 'Pro',
    'lifetime': 'Lifetime'
};
exports.PlatformOptions = {
    'ios': 'iOS',
    'android': 'Android',
    'web': 'Web',
    'macos': 'Mac OS',
    'windows': 'Windows'
};
exports.SubscriptionStatusOptions = {
    'active': 'Active',
    'expired': 'Expired',
    'cancelled': 'Cancelled',
    'waiting': 'Waiting',
    'pending': 'Pending',
    'error': 'Error',
    'unknown': 'Unknown',
    'completed': 'Completed'
};
exports.productSkus = [
    'com.logisian.mapulator.feature',
];
exports.PurchaseOptions = {
    [exports.productSkus[0]]: 'Lifetime'
};
exports.subscriptionSkus = [];
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
Upgrades_1.plans.forEach((plan) => {
    plan.skus.forEach((sku) => {
        const comps = sku.split('.');
        exports.PurchaseOptions[sku] = `${plan.title} - ${capitalizeFirstLetter(comps[comps.length - 1])}`;
        exports.subscriptionSkus.push(sku);
    });
});
exports.RateResultOptions = {
    'denied': 'Denied',
    'error': 'Error',
    'success': 'Success',
    'unknown': 'Unknown'
};
var Users;
(function (Users) {
    function createUser(userId, document) {
        return new Promise(async (resolve, reject) => {
            try {
                const previousUserResponse = await getUsers({
                    where: [
                        {
                            fieldPath: 'email',
                            opStr: '==',
                            value: document.email
                        }
                    ],
                    limit: 1,
                    orderBy: {
                        fieldPath: 'numberOfSessions',
                        directionStr: 'desc'
                    }
                });
                if (previousUserResponse.data.length > 0) {
                    const previousUser = previousUserResponse.data[0];
                    document.numberOfSessions = previousUser.numberOfSessions;
                }
                const response = await firestar_1.Actions.writeDocument(`users/${userId}`, document);
                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Users.createUser = createUser;
    function getUserDetails(userId) {
        return firestar_1.Actions.readDocument(`users/${userId}`);
    }
    Users.getUserDetails = getUserDetails;
    function getUsers(descriptor) {
        return firestar_1.Actions.readCollection('users', descriptor);
    }
    Users.getUsers = getUsers;
    function updateUser(userId, document, batch) {
        return firestar_1.Actions.updateDocument(`users/${userId}`, document, batch);
    }
    Users.updateUser = updateUser;
    function deleteUser(userId) {
        return firestar_1.Actions.deleteDocument(`users/${userId}`);
    }
    Users.deleteUser = deleteUser;
    function incrementRewardPoints(userId, change) {
        const firestore = firestar_1.default.getFirestore();
        const increment = firestore.FieldValue.increment(change !== null && change !== void 0 ? change : 1);
        return firestar_1.Actions.updateDocument(`users/${userId}`, {
            rewardPoints: increment
        });
    }
    Users.incrementRewardPoints = incrementRewardPoints;
    function getSearchTags(document) {
        return firestar_1.Actions.appendSearchTags(document, {
            splitKeys: [
                'displayName',
                'email'
            ],
            absoluteKeys: [],
            createMatches: true,
            includeDocumentId: true,
            includeSpecialCharacters: true
        });
    }
    Users.getSearchTags = getSearchTags;
    function updateSearchTags(userId, document) {
        return firestar_1.Actions.updateDocument(`users/${userId}`, getSearchTags(document));
    }
    Users.updateSearchTags = updateSearchTags;
    function getUserInfo(incomingUser) {
        var _a, _b, _c, _d, _e;
        const auth = firestar_1.default.getAuth();
        const firebaseUser = incomingUser !== null && incomingUser !== void 0 ? incomingUser : auth().currentUser;
        return {
            displayName: (_a = firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.displayName) !== null && _a !== void 0 ? _a : null,
            phoneNumber: (_b = firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.phoneNumber) !== null && _b !== void 0 ? _b : null,
            email: (_c = firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.email) !== null && _c !== void 0 ? _c : null,
            photoURL: (_d = firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.photoURL) !== null && _d !== void 0 ? _d : null,
            uid: (_e = firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.uid) !== null && _e !== void 0 ? _e : '',
            providerId: ''
        };
    }
    Users.getUserInfo = getUserInfo;
    function logPurchase(userId, purchase) {
        const path = `users/${userId}/purchases/${purchase.transactionId}`;
        return firestar_1.Actions.writeDocument(path, purchase);
    }
    Users.logPurchase = logPurchase;
    function updatePurchaseLog(userId, purchaseId, purchase) {
        const path = `users/${userId}/purchases/${purchaseId}`;
        return firestar_1.Actions.writeDocument(path, purchase);
    }
    Users.updatePurchaseLog = updatePurchaseLog;
    function registerPurchase(userId, purchase, force) {
        return new Promise(async (resolve, reject) => {
            try {
                // let where = [] as WhereType[]
                // if(purchase.platform === 'ios'){
                //    where = [
                //       {
                //          fieldPath: 'purchase.originalTransactionIdentifierIOS',
                //          opStr: '==',
                //          value: purchase.originalTransactionIdentifierIOS
                //       }
                //    ]
                // }else if(purchase.platform === 'android'){
                //    const transactionId = purchase.transactionId
                //    if(transactionId != null){
                //       where = [
                //          {
                //             fieldPath: 'purchase.transactionId',
                //             opStr: '==',
                //             value: transactionId
                //          }
                //       ]
                //    }else{
                //       throw new Error("Order Id Not found for Purchase");
                //    }
                // }else{
                //    throw new Error("Not Supported");               
                // }
                // const response = await Actions.readCollection('users', {
                //    where: where
                // })
                // const duplicatePurchases = response.data.filter(
                //    (user) => user.uid !== userId
                // ) as User[]
                // if (duplicatePurchases.length > 0) {
                //    if ((force ?? false) == true) {
                //       await Actions.batchUpdate(duplicatePurchases.map((user) => {
                //          return {
                //             path: `users/${user.id}`,
                //             document: {
                //                purchase: null
                //             } as User
                //          } 
                //       }))
                await firestar_1.Actions.updateDocument(`users/${userId}`, {
                    purchase: purchase
                });
                await logPurchase(userId, purchase);
                // } else {
                //    const error = new Error('This In App Purchase is already being used by a different account.')
                //    error.name = 'duplicate-purchase'
                //    throw error
                // }
                // } else {
                //    await Actions.updateDocument<User>(`users/${userId}`, {
                //       purchase: purchase
                //    })
                //    await logPurchase(userId, purchase)
                // }
                resolve();
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Users.registerPurchase = registerPurchase;
    function acknowledgePurchase(userId, purchaseId, platform) {
        return new Promise(async (resolve, reject) => {
            try {
                let payload = undefined;
                let logUpdate = undefined;
                if (platform === 'android') {
                    payload = {
                        'purchase.isAcknowledgedAndroid': true,
                    };
                    logUpdate = {
                        isAcknowledgedAndroid: true
                    };
                }
                else if (platform === 'ios') {
                    payload = {
                        'purchase.isAcknowledgedIOS': true
                    };
                    logUpdate = {
                        isAcknowledgedIOS: true
                    };
                }
                else if (platform === 'web') {
                    payload = {
                        'purchase.isAcknowledgedWeb': true
                    };
                    logUpdate = {
                        isAcknowledgedWeb: true
                    };
                }
                else {
                    throw new Error("Platform Not Supported");
                }
                payload['purchase.isAcknowledged'] = true;
                logUpdate['isAcknowledged'] = true;
                const response = await firestar_1.Actions.updateDocument(`users/${userId}`, payload);
                await updatePurchaseLog(userId, purchaseId, logUpdate);
                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Users.acknowledgePurchase = acknowledgePurchase;
    function invalidatePurchase(userId) {
        return firestar_1.Actions.updateDocument(`users/${userId}`, {
            purchase: null
        });
    }
    Users.invalidatePurchase = invalidatePurchase;
    function __deleteAccount(userId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = 'https://us-central1-logisian-mapulator-92210.cloudfunctions.net/deleteAccount';
                const body = {
                    userId
                };
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                });
                if (response.ok) {
                    const responseJSON = await response.json();
                    resolve(responseJSON);
                }
                else {
                    throw {
                        status: response.statusText,
                        result: await response.json()
                    };
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Users.__deleteAccount = __deleteAccount;
    function getPurchaseStatus(purchase, userInfo) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = 'https://us-central1-logisian-mapulator-92210.cloudfunctions.net/validatePurchase2';
                // const url = "http://127.0.0.1:5001/logisian-mapulator-92210/us-central1/validatePurchase2"
                const body = {
                    purchase,
                    userInfo,
                };
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                });
                const responseJSON = await response.json();
                resolve(responseJSON);
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Users.getPurchaseStatus = getPurchaseStatus;
    function updateMembership(userId, document, batch) {
        let membershipType = getMembershipTypeForUser(document);
        if (membershipType !== document.membershipType) {
            return firestar_1.Actions.updateDocument(`users/${userId}`, {
                membershipType
            }, batch);
        }
        else {
            return Promise.resolve();
        }
    }
    Users.updateMembership = updateMembership;
    function getMembershipTypeForUser(document) {
        var _a;
        let membershipType = 'trial';
        const purchase = document.purchase;
        if (purchase) {
            const productId = purchase.productId;
            if (productId == null) {
                console.log('Product Id Not Found for', document.id);
            }
            else {
                membershipType = productId.split('.')[productId.split('.').length - 1];
            }
        }
        else {
            if (((_a = document.numberOfSessions) !== null && _a !== void 0 ? _a : 0) <= 10) {
                membershipType = 'trial';
            }
            else {
                membershipType = 'free';
            }
        }
        return membershipType;
    }
    Users.getMembershipTypeForUser = getMembershipTypeForUser;
    function getSubscriptionPlan(user) {
        var _a;
        if ((user === null || user === void 0 ? void 0 : user.purchase) != null) {
            const productId = (_a = user.purchase) === null || _a === void 0 ? void 0 : _a.productId;
            const comps = productId.split('.');
            if (comps.length == 5) {
                const lastTwo = comps.slice(comps.length - 2, comps.length);
                return lastTwo.join('-');
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
    Users.getSubscriptionPlan = getSubscriptionPlan;
    function createOrder() {
        return new Promise(() => {
            const url = 'https://api.razorpay.com/v1/orders';
        });
    }
    Users.createOrder = createOrder;
})(Users || (Users = {}));
exports.default = Users;
