import { View, Text, Image } from 'react-native'
import React, { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
import TextField from './utils/TextField'
import Grid from '@material-ui/core/Grid'
import {
   Button,
   Divider
} from 'react-native-elements';
import { useHistory, useParams } from 'react-router-dom'
import { Alert } from './utils/Alert'
import Progress from './utils/Progress.web'
import firestar from 'firestar'

export default function Login() {

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [showLoader, setShowLoader] = useState(true)
	const hisotry = useHistory()
	const redirect = new URLSearchParams(window.location.search).get('redirect')
	const [userId] = useState<string | null>(firestar.getLoginId())

	useEffect(() => {
		if (userId) {
			window.open(redirect ?? '/', '_self')
		} else {
			firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
				.then(() => {
					setShowLoader(false)
				})
				.catch((error) => {
					// Handle Errors here.
					var errorCode = error.code;
					var errorMessage = error.message;
					console.log('Error Settings Persistance', error)
				});
		}
	}, [userId])

	if(showLoader == true){
		return <Progress/>
	}

	return (
		<Grid style={{
			display: 'flex',
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			alignSelf: 'center',
			width: '100%',
		}} xs={12} sm={10} md={6} lg={5} xl={4}>
			<div style={{
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				padding: '1em',
				width: '100%',
				justifyContent: 'center',
				minHeight: window.innerHeight,
			}}>
				<View>
					<View style={{
						flex: 1,
						alignItems: 'center'
					}}>
						<Image 
							style={{
								height: 100,
								width: 220,
								resizeMode: 'contain',
								alignItems: 'center'
							}}
							source={require('./res/logo-small.png')}/>
					</View>
					<TextField
						style={{
							marginVertical: 5
						}}
						accessibilityLabel={'email'}
						testID={'email'}
						label='Email'
						placeholder='Enter email'
						value={email}
						onChangeText={(value) => setEmail(value.trim())} />
					<TextField
						style={{
							marginVertical: 5
						}}
						accessibilityLabel={'password'}
						testID={'password'}
						secureTextEntry
						label='Password'
						placeholder='Enter password'
						value={password}
						onChangeText={(value) => setPassword(value.trim())} />
					<Button
						onPress={onPressForgotPassword}
						containerStyle={{
							width: '50%',
							alignSelf: 'flex-end',
							marginBottom: 15,
							padding: 0,
						}}
						buttonStyle={{
							margin: 0,
							padding: 0,
						}}
						type='clear'
						titleStyle={{
							fontWeight: 'bold',
							textAlign: 'right',
							width: '100%',
							fontSize: 15
						}}
						accessibilityLabel={'forgotPassword'}
						testID={'forgotPassword'}
						title='Forgot Password?' />
					<Button
						containerStyle={{
							marginVertical: 5
						}}
						buttonStyle={{
							height: 45
						}}
						titleStyle={{
							fontWeight: 'bold'
						}}
						accessibilityLabel={'login'}
						testID={'login'}
						onPress={onPressLogin}
						title='LOGIN' />
					<Text style={{
						flex: 1,
						textAlign: 'center',
						marginVertical: 10
					}}>OR</Text>
					<View style={{
						alignItems: 'center',
						justifyContent: 'center',
					}}>
						<Button
							containerStyle={{
								width: '100%',
								marginVertical: 5,
							}}
							titleStyle={{
								fontSize: 16,
								fontWeight: '600'
							}}
							accessibilityLabel={'sign-in-with-google'}
							testID={'sign-in-with-google'}
							onPress={onPressSignInWithGoogle}
							buttonStyle={{
								backgroundColor: 'red',
								height: 45,
								borderRadius: 5
							}}
							title='Sign In With Google' />
					</View>
					<Divider style={{ marginTop: 20 }} />
					{/* <Button
						containerStyle={{
							marginVertical: 10
						}}
						buttonStyle={{
							height: 45
						}}
						onPress={onPressSignUp}
						type='clear'
						titleStyle={{
							fontWeight: 'bold',
							fontSize: 15
						}}
						accessibilityLabel={'create-account'}
						testID={'create-account'}
						title='CREATE AN ACCOUNT' /> */}
				</View>
			</div>
		</Grid>
	)

	async function onPressForgotPassword() {

      if (email.trim().length == 0) {
         Alert.alert('Invalid Email','Please enter an email address')
         return
      }

      setShowLoader(true)
      try {
         await firebase.auth().sendPasswordResetEmail(email)
			
         Alert.alert(
            'Reset Link Sent',
            'A password reset link has been to sent to your email address. Please check your email for further instructions'
         )
      } catch (error) {
         Alert.alert(
            'Error Resetting Password',
            String(error)
         )
         console.log('Error Resetting Password', error)
      }
      setShowLoader(false)
   }

	async function onPressLogin() {
      if (password.length == 0 || email.length == 0) {
         return Alert.alert('Error', 'Enter all fields to continue')
      }
      setShowLoader(true)
      try {
         const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password)
         onCompleteSignUp(userCredential)
      } catch (error) {
         Alert.alert(
            'Error Logging In',
            String(error)
         )
         console.log('Error Logging In', error)
      }
      setShowLoader(false)
   }

	function onPressSignUp() {
		hisotry.push('/signup')
   }

	async function onCompleteSignUp(userCredential: firebase.auth.UserCredential) {
      setShowLoader(true)
      try {
         if(redirect){
			window.open(redirect, '_self') 
		}else{
			window.open('/', '_self')
		}
      } catch (error) {
         console.log('Error Creating User Account ', error)
         Alert.alert('Error Creating User Account', String(error))
      }
      setShowLoader(false)
   }

	async function onPressSignInWithGoogle() {
      setShowLoader(true)
      try {

			const auth = firebase.auth();
			const googleProvider = new firebase.auth.GoogleAuthProvider()
			googleProvider.setCustomParameters({
				prompt: 'select_account'
			})
			const credential = await auth.signInWithPopup(googleProvider)
			onCompleteSignUp(credential)

      } catch (__error) {
         const error = __error as any
         if(error.code == 'auth/popup-closed-by-user'){
            //ignore
         }else{
				Alert.alert('Error Signing In', String(error))
         }
         console.log('Error Signing with Google :', JSON.stringify(error))         
      }
		setShowLoader(false)
   }
}