import React, { useEffect, useState } from 'react'
import SessionView from './SessionView'
import Downloads from './Downloads'
import firestar from 'firestar'
import { Icon } from 'react-native-elements'

export default function Home() {

    const [userId] = useState<string | null>(firestar.getLoginId())

    return (
        <div>
            <div style={{
                position: 'absolute'
            }}>
                <SessionView/>
            </div>
            <div style={{
                display: "flex",
                position: 'absolute',
                zIndex: 100,
                alignSelf: 'center',
                top: window.innerHeight - (window.innerHeight/1.5),
                right: 0,
                left: 0,
                background: 'white',
                justifyContent: 'center',
                padding: '2em',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <a href='https://mapulator.app' target='_self'>
                    <img alt='mapulator' style={{
                        width: 180,
                        height: 60
                    }} src={require('./res/logo-small.png')}/>
                </a>
                <h1>Mapulator is currently not available on the web.</h1>
                <Downloads/>
            </div>
        </div>
    )
}
