import React, { Component } from 'react';
import {
   isMobile
} from 'react-device-detect'
import {
   View,
   TouchableOpacity,
   Platform,
   Linking,
   Text
} from 'react-native'
import {
   Button
} from 'react-native-elements';

export default class Downloads extends Component<any> {

   render() {
      return (
         <View style={{
            justifyContent: 'center'
         }}>
            <Text style={{
               fontSize: 24,
               textAlign: 'center',
               marginBottom: 15
            }}>Get Mapulator on your Android and Apple Device</Text>
            <View style={{
               justifyContent: 'space-between',
               alignItems: 'center'
            }}>
               {Platform.OS == 'web' && <View>
                  <View style={{
                     alignItems: 'center'
                  }}>
                     <ImageButton
                        alt='mapulator-appstore'
                        onPress={() => this.openLink('ios')}
                        image={require('./res/apple-store.png')} />
                     <ImageButton
                        alt='mapulator-playstore'
                        onPress={() => this.openLink('android')}
                        image={require('./res/play-store.png')} />
                  </View>
               </View>}
            </View>
            {/* <Button 
               containerStyle={{
                  marginTop: 15
               }}
               titleStyle={{
                  fontSize: 17,
                  fontWeight: 'bold'
               }}
               onPress={() => this.props.history.push('/')}
               title='CONTINUE ON WEB'/> */}
         </View>
      );
   }

   openLink = (osName: 'ios' | 'android') => {
      switch (osName) {
         case 'ios':
            openInNewTab('https://apps.apple.com/app/mapulator-map-measure/id591577052')
            break;
         case 'android':
            openInNewTab('https://play.google.com/store/apps/details?id=com.logisian.mapulator')
            break;
         default:
            break;
      }
   }
}

function openInNewTab(url: string) {
   var win = window.open(url, '_blank') as any
   win.focus();
}

function ImageButton(props: {
   image: any,
   alt: string,
   onPress: () => void
}) {
   return (
      <TouchableOpacity onPress={props.onPress} style={{
         margin: 5
      }}>
         <img style={{
            width: 150,
            height: 50
         }} src={props.image} />
      </TouchableOpacity>
   )
}
