import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import firestar from 'firestar'
import {
   Upgrades,
   Users
} from 'mcore'
import { Alert } from './utils/Alert.web';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Progress from './utils/Progress';
import { Image, Platform, Text, View } from 'react-native';
import { Button } from 'react-native-elements';
import { plans } from 'mcore/lib/Upgrades';

interface Params {
   productId: string
}

export default function Upgrade() {

   const Razorpay = useRazorpay();
   const params = useParams() as Params
   const [plan, setPlan] = useState<{
      id: string,
      item: {
         amount: number,
         currency: string,
         name: string
      },
      notes: {
         productId: string
      },
      period: string
   }>()
   const userInfo = Users.getUserInfo()
   const [showLoader, setShowLoader] = useState(true)
   const planInfo = useMemo(() => {
      if(plan){
         const __plan = plans.find(p => p.skus.includes(plan.notes.productId))
         return __plan
      }
      return null
   }, [plan])
   const isTesting = useMemo(() => {
      const __isTesting = new URLSearchParams(window.location.search).get('isTesting')
      return __isTesting == 'true'
   }, [])
   const offerId = useMemo(() => {
      const __offerId = new URLSearchParams(window.location.search).get('offerId')
      return __offerId != null ? String(__offerId) : undefined
   }, [])

   useEffect(() => {
      const _ = async () => {
         setShowLoader(true)
         try {
            const planResponse = await Upgrades.getPlan(
               params.productId,
               isTesting ? 'true' : 'false'
            )
            setPlan(planResponse as any)
            console.log('Plan', planResponse)
            setShowLoader(false)
         } catch (error) {
            Alert.alert('Error Getting Plan', String(error))
            console.log('Error Getting Plan', error)
            window.open('/')
         }
      }
      _()
   }, [])

   if(showLoader == true || plan == null){
      return <Progress/>
   }

   return (
      <Grid style={{
         display: 'flex',
         flex: 1,
         justifyContent: 'center',
         alignItems: 'center',
         alignSelf: 'center',
         width: '100%',
         padding: '2em',
         height: window.innerHeight,
      }} xs={12} sm={10} md={6} lg={5} xl={4}>
         <div style={{
            padding: '1em',
            width: '100%'
         }}>
            <Paper elevation={1} variant='outlined' style={{
               padding: '2em',
            }}>
               <View style={{
                  flex: 1,
                  alignItems: 'center',
                  alignSelf: 'center'
               }}>
                  <View style={{
                     width: 200
                  }}>
                     <Image
                        style={{
                           height: 100,
                           // width: 220,
                           resizeMode: 'contain',
                           alignItems: 'center'
                        }}
                        source={require('./res/logo-small.png')} />
                     <Text style={{
                        position: 'absolute',
                        right: 0,
                        bottom: 20,
                        fontWeight: 'bold'
                     }}>PREMIUM</Text>
                  </View>
               </View>
               <br />
               <View>
                  <b>{userInfo.displayName}</b>
                  <div>{userInfo.email}</div>
               </View>
               <br />
               <View>
                  <b><div style={{
                     marginBottom: 5
                  }}>Plan Details:</div></b>
                  <View>
                     <Text>{plan?.item.name}</Text>
                     <Text style={{
                        fontSize: 30,
                        fontWeight: 'bold'
                     }}>{plan?.item.currency} {plan?.item.amount / 100}<Text style={{
                        fontSize: 18,
                        fontWeight: 'normal'
                        }}>/{plan.period.substring(0, plan.period.length - 2)}</Text></Text>
                     <br />
                     {planInfo != null && <View>
                        <b>Features: </b>
                        {planInfo.features.map((p) => (
                           <Text style={{ marginVertical: 5 }}>• {p}</Text>
                        ))}
                     </View>}
                  </View>
               </View>
               <br />
               {plan != null && <Button buttonStyle={{
                  height: 50
               }} title='Subscribe' onPress={onPressPay} />}
            </Paper>
         </div>
      </Grid>
   )

   async function onPressPay() {

      setShowLoader(true)

      try {

         const userId = firestar.getLoginId()
         if (userId == null) {
            window.open('/login?redirect=/upgrade/' + params.productId, '_self'); return;
         }
         if (plan == null) { 
            throw new Error("Plan Not Found");
         }

         // const __offerId = offerId ?? (isTesting ? 'offer_LttuN83pjCO34J' : 'offer_LjEzLKIa7aRX6M')
         const subscriptionResponse = await Upgrades.createSubscription(
            plan.id, 
            userInfo, 
            offerId,
            isTesting
         )
         
         const customer = subscriptionResponse.customer
         const subscription = subscriptionResponse.subscription

         console.log('Customer', customer)
         console.log('Subscription', subscription)

         let subscriptionId = subscription.id
         const key = isTesting ? "rzp_test_jbvc0UCzXdxQXM" : "rzp_live_fZmw8YxBM6LZdG"

         const options = {
            key: key,
            // order_id: '',
            subscription_id: subscriptionId,
            amount: String(plan.item.amount),
            currency: plan.item.currency,
            name: plan.item.name,
            description: [
               'Fullname: ' + userInfo.displayName,
               'Email: ' + userInfo.email,
               'Uid: ' + userInfo.uid,
               'Customer Id: ' + customer.id 
            ].join('\n'),
            image: "https://mapulator.app/wp-content/uploads/2022/04/mapulator-small.png",
            handler: ({ razorpay_order_id, razorpay_payment_id, razorpay_signature }) => {
               onPaymentSuccess(razorpay_order_id, razorpay_payment_id, razorpay_signature, subscriptionId)
            },
            hidden: {
               contact: true,
            },
            // customer_id: customer.id,
            remember_customer: true,
            prefill: {
               name: userInfo.displayName ?? undefined,
               email: userInfo.email ?? undefined,
            },
            notes: {
               'uid': userInfo.uid,
               'email': userInfo.email,
               'displayName': userInfo.displayName,
               'customerId': customer.id
            },
            theme: {
               color: "#3399cc",
            },
            recurring: true,
         } as Partial<RazorpayOptions>

         console.log('Options', options)

         const razorpay = new Razorpay(options as RazorpayOptions);

         razorpay.on("payment.failed", onPaymentFailure);
         razorpay.open();

      } catch (error) {
         console.log('Error with subscription', error)
         Alert.alert('Error Completing Subscription', String(error))
      }

      setShowLoader(false)
   }

   function onPaymentFailure(error: any) {
      console.log('Payment Failure', error)
      Alert.alert('Subscription Payment Failure', String(error))
   }

   async function onPaymentSuccess(
      orderId: string,
      paymentId: string,
      signature: string,
      subscriptionId: string
   ) {
      console.log('Payment Success', orderId, paymentId, signature)
      setShowLoader(true)
      try {

         const purchase = {
            platform: Platform.OS,
            transactionId: orderId ?? subscriptionId,
            productId: params.productId,
            subscription: true,
            isTestEnvironment: isTesting,
            paymentId,
            signature,
            transactionDate: new Date().getTime(),
         } as Upgrades.Purchase

         await Users.registerPurchase(userInfo.uid, purchase)

         if(purchase.transactionId){
            await Users.acknowledgePurchase(userInfo.uid, purchase.transactionId, 'web') 
         }
         
         Alert.alert('Purchase Complete', 'You can now start using Mapulator Premium on your phone. Please close and open the app.')
         window.open('/', '_self')
      } catch (error) {
         console.log('Error Completing Purchase', error)
         Alert.alert('Error Completing Purchase', String(error))
      }
      setShowLoader(false)
   }
}
